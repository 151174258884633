import { extendTheme } from '@chakra-ui/react'
import { theme } from '@/lib/chakra-ui-pro-theme'

const colors = {
  ...theme.colors,
  brand: theme.colors.blue,
}

const extendedTheme = extendTheme(
  {
    colors,
  },
  theme,
)

export default extendedTheme
