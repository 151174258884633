import type { AppProps } from 'next/app'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { ApolloProvider } from '@apollo/client'
import { SWRConfig } from 'swr'
import { ChakraProvider } from '@chakra-ui/react'

import { backendClient } from '@/lib'
import { theme } from '@/config'
import '../styles/globals.css'
import '@fontsource/inter/variable.css'

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <ApolloProvider client={backendClient}>
      <SWRConfig
        value={{
          fetcher: (url, init) => fetch(url, init).then((res) => res.json()),
        }}
      >
        <ChakraProvider theme={theme}>
          <SessionProvider session={session}>
            <Component {...pageProps} />
          </SessionProvider>
        </ChakraProvider>
      </SWRConfig>
    </ApolloProvider>
  )
}

export default MyApp
