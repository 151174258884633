// https://www.apollographql.com/docs/react/get-started
import * as Sentry from '@sentry/nextjs'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
  relayStylePagination,
  offsetLimitPagination,
} from '@apollo/client/utilities'

import result from '@/generated/graphql'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
      return Sentry.captureMessage(message)
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    Sentry.captureException(networkError)
  }
})

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // authorization: `Bearer ${process.env.}`,
    },
  }
})

const backendClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  cache: new InMemoryCache({
    possibleTypes: result.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          allEventRsvps: relayStylePagination(['event']),
        },
      },
    },
  }),
  link: ApolloLink.from([authLink, errorLink, httpLink]),
})

export default backendClient
